// Imports => Constants
import { ICONS, KEYS } from '@constants';

export const AcGetEquipmentIcon = (type) => {
	let result = null;
	if (!type) return result;

	const entity = type.replace('-', '_').replace(/ /g, '_').toLowerCase();

	switch (entity) {
		case KEYS.CONTROL_UNIT:
		case KEYS.CONTROL_UNITS:
			result = ICONS.CONTROL_UNIT;
			break;

		case KEYS.HAMMER:
		case KEYS.HAMMERS:
		case KEYS.HYDROHAMMER:
		case KEYS.HYDROHAMMERS:
			result = ICONS.HAMMER;
			break;

		case KEYS.VIBRATORY_HAMMER:
		case KEYS.VIBRATORY_HAMMERS:
			result = ICONS.VIBROHAMMER_OUTLINE;
			break;

		case KEYS.SLEEVE:
		case KEYS.SLEEVES:
			result = ICONS.SLEEVE_OUTLINE;
			break;

		case KEYS.PULSE:
		case KEYS.PULSES:
			result = ICONS.PULSE_OUTLINE;
			break;

		case KEYS.POWERPACK:
		case KEYS.POWERPACKS:
		case KEYS.POWER_PACKS:
			result = ICONS.POWERPACK;
			break;

		default:
	}

	return result;
};

export default AcGetEquipmentIcon;
