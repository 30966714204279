import { generatePath } from 'react-router-dom';

// Imports => Constants
import { KEYS, ROUTES } from '@constants';

// Imports => Utilities
import { AcIsSet } from './ac-get-type-of';

const GetRoute = ({ entity }) => {
  let result = null;

  if (!AcIsSet(entity)) return null;

  const type = entity.replace(/-/g, '_').replace(/ /g, '_').toLowerCase();

  switch (type) {
    case KEYS.COMPANIES:
    case KEYS.COMPANY:
      result = ROUTES.COMPANY_DETAIL.path;
      break;

    case KEYS.CONFIGURATIONS:
    case KEYS.CONFIGURATION:
      result = ROUTES.CONFIGURATION_DETAIL.path;
      break;

    case KEYS.CONTRACTS:
    case KEYS.CONTRACT:
      result = ROUTES.CONTRACT_DETAIL.path;
      break;

    case KEYS.CONTROL_UNITS:
    case KEYS.CONTROL_UNIT:
      result = ROUTES.CONTROL_UNIT_DETAIL.path;
      break;

    case KEYS.HYDROHAMMERS:
    case KEYS.HYDROHAMMER:
    case KEYS.HAMMERS:
    case KEYS.HAMMER:
      result = ROUTES.HYDROHAMMER_DETAIL.path;
      break;

    case KEYS.POWERPACKS:
    case KEYS.POWERPACK:
    case KEYS.POWER_PACKS:
    case KEYS.POWER_PACK:
      result = ROUTES.POWERPACK_DETAIL.path;
      break;

    case KEYS.PROJECTS:
    case KEYS.PROJECT:
      result = ROUTES.PROJECT_DETAIL.path;
      break;

    case KEYS.USERS:
    case KEYS.USER:
      result = ROUTES.USER_DETAIL.path;
      break;

    case KEYS.CONTROL_UNIT_TYPES:
    case KEYS.CONTROL_UNIT_TYPE:
      result = ROUTES.CONTROL_UNIT_TYPE_DETAIL.path;
      break;

    case KEYS.HYDROHAMMER_TYPES:
    case KEYS.HYDROHAMMER_TYPE:
    case KEYS.HAMMER_TYPES:
    case KEYS.HAMMER_TYPE:
      result = ROUTES.HYDROHAMMER_TYPE_DETAIL.path;
      break;

    case KEYS.POWERPACK_TYPES:
    case KEYS.POWERPACK_TYPE:
      result = ROUTES.POWERPACK_TYPE_DETAIL.path;
      break;

    case KEYS.PULSE:
    case KEYS.PULSES:
      result = ROUTES.PULSE_DETAIL.path;
      break;

    case KEYS.PULSE_TYPES:
    case KEYS.PULSE_TYPE:
      result = ROUTES.PULSE_TYPE_DETAIL.path;
      break;

    case KEYS.SLEEVE:
    case KEYS.SLEEVES:
      result = ROUTES.SLEEVE_DETAIL.path;
      break;

    case KEYS.SLEEVE_TYPES:
    case KEYS.SLEEVE_TYPE:
      result = ROUTES.SLEEVE_TYPE_DETAIL.path;
      break;

    case KEYS.VIBRATORY_HAMMER:
    case KEYS.VIBRATORY_HAMMERS:
      result = ROUTES.VIBRATORY_HAMMER_DETAIL.path;
      break;

    case KEYS.VIBRATORY_HAMMER_TYPES:
    case KEYS.VIBRATORY_HAMMER_TYPE:
      result = ROUTES.VIBRATORY_HAMMER_TYPE_DETAIL.path;
      break;

    default:
  }

  return result;
};

export const AcFormatInternalURI = (link, value) => {
  if (!link || !value) return null;

  let route = GetRoute(link);

  if (route) {
    route = generatePath(route, { id: link.id });
    // route = route.replace(':id', link.id);
  }

  return route;
};
