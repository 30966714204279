// Imports => React
import React, { useState, useEffect, useMemo, memo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => Constants
import { COLUMNS, KEYS, PERMISSIONS, TITLES, TYPES } from '@constants';

// Imports => Utilities
import {
  AcIsSet,
  AcIsUndefined,
  AcFormatInternalURI,
  AcFormatMapURL,
  AcSortBy,
} from '@utils';

// Imports => Hooks
import { usePermissions } from '@hooks';

// Imports => Molecules
const AcEditVibratoryHammerModal = loadable(() =>
  import(
    '@molecules/ac-edit-vibratory-hammer-modal/ac-edit-vibratory-hammer-modal.web'
  )
);

// Imports => Components
const AcDetailsCard = loadable(() =>
  import('@components/ac-details-card/ac-details-card.web')
);
const AcHeroVisual = loadable(() =>
  import('@components/ac-hero-visual/ac-hero-visual.web')
);
const AcTable = loadable(() => import('@components/ac-table/ac-table.web'));
import { useRenderContracts } from '@components/ac-contract-table';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
const AcHeading = loadable(() => import('@atoms/ac-heading/ac-heading.web'));
const AcCard = loadable(() => import('@atoms/ac-card/ac-card.web'));
const AcLoader = loadable(() => import('@atoms/ac-loader/ac-loader.web'));
const AcEmptyBlock = loadable(() =>
  import('@atoms/ac-empty-block/ac-empty-block.web')
);

const _CLASSES = {
  MAIN: 'ac-vibratory_hammer-detail-overview-tab',
};

const AcVibratoryHammerDetailOverviewTab = ({
  data,
  store: { vibratory_hammers, ui },
}) => {
  const { can, cannot } = usePermissions();

  const contracts = useRenderContracts(data.contracts);

  const displayEditModal = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: `${TITLES.EDIT_VIBRATORY_HAMMER}: ${data.object_no}`,
      body: (
        <AcEditVibratoryHammerModal
          data={data}
          submit={vibratory_hammers.update}
        />
      ),
      closeable: true,
      visible: true,
      actions: [],
      callback: () => {
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const renderDetails = useMemo(() => {
    if (!data) return null;

    const {
      equipment_type,
      object_no,
      company,
      location,
      used_by,
      contract_type,
    } = data;

    let items = [
      [
        {
          label: 'Object number',
          value: object_no || '-',
        },
      ],
      [],
    ];

    let object_type = {
      label: 'Equipment type',
      value: (equipment_type && equipment_type.name) || '-',
    };

    if (can(PERMISSIONS.EQUIPMENT_TYPE.READ)) {
      const object_route =
        (equipment_type &&
          AcFormatInternalURI(
            {
              id: equipment_type.id,
              entity: KEYS.VIBRATORY_HAMMER_TYPES,
              equipment_group: KEYS.VIBRATORY_HAMMERS,
            },
            'View object type'
          )) ||
        null;

      if (object_route)
        object_type = { ...object_type, type: TYPES.LINK, to: object_route };
    }
    items[0].push(object_type);

    if (can(PERMISSIONS.COMPANY.READ_ALL)) {
      const company_route =
        (company &&
          AcFormatInternalURI(
            { id: company.id, entity: KEYS.COMPANY },
            'View company'
          )) ||
        null;

      if (!items?.[1]) items.push([]);
      items[1].push({
        label: 'Company',
        value: (company && company.name) || '-',
        type: TYPES.LINK,
        to: company_route,
      });
    }

    if (can(PERMISSIONS.COMPANY.READ)) {
      if (!AcIsUndefined(used_by)) {
        const used_by_route =
          (used_by?.id &&
            AcFormatInternalURI(
              { id: used_by.id, entity: KEYS.COMPANY },
              'View company'
            )) ||
          null;

        if (!used_by?.name) {
          items[1].push({
            label: 'Used by',
            value: used_by || '-',
          });
        } else {
          items[1].push({
            label: 'Used by',
            value: used_by?.name || '-',
            type: TYPES.LINK,
            to: used_by_route,
          });
        }
      }
    }

    if (can(PERMISSIONS.CONTRACT.READ_ALL)) {
      if (!AcIsUndefined(contract_type)) {
        items[1].push({
          label: 'Contract type',
          value: contract_type || '-',
        });
      }
    }

    const edit = can(PERMISSIONS.EQUIPMENT.MANAGE_DETAILS) && displayEditModal;

    return <AcDetailsCard title={TITLES.DETAILS} items={items} edit={edit} />;
  }, [data]);

  const renderHeroVisual = useMemo(() => {
    if (!data) return null;

    const { equipment_type } = data;

    if (!equipment_type || !equipment_type.header_image) return null;

    return <AcHeroVisual image={equipment_type.header_image} />;
  }, [data]);

  const renderConfigurationsTable = useMemo(() => {
    if (cannot(PERMISSIONS.CONFIGURATION.READ)) return null;
    if (!AcIsSet(data?.configurations)) return null;
    if (!data?.configurations?.length)
      return <AcEmptyBlock message={'No configurations found.'} />;

    const collection = data?.configurations;
    let rows = collection.map((item) => ({
      ...item,
    }));

    rows = AcSortBy({
      collection: rows,
      field: 'name',
      direction: 'asc',
    });

    const columns = COLUMNS.CONFIGURATION_EQUIPMENT.filter((column) => {
      if (column.key === 'company' && cannot(PERMISSIONS.COMPANY.READ)) {
        return false;
      }
      return true;
    });

    const options = {
      columns,
      rows,
      pagination: null,
      withIcon: true,
    };

    return (
      <Fade duration={300}>
        <AcTable {...options} />
      </Fade>
    );
  }, [data?.configurations]);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  });

  return (
    <div className={getMainClassNames}>
      <AcContainer fluid>
        <AcRow>
          <AcColumn xs={12} sm={6}>
            <AcRow>
              <AcColumn>
                <AcHeading tag={'h2'} rank={5} className={'h-margin-bottom-25'}>
                  Vibratory hammer details
                </AcHeading>
              </AcColumn>
            </AcRow>
            <AcRow>
              <AcColumn>{renderDetails}</AcColumn>
            </AcRow>
          </AcColumn>

          {renderHeroVisual && (
            <AcColumn xs={12} sm={6}>
              <AcRow>
                <AcColumn>
                  <AcHeading
                    tag={'h2'}
                    rank={5}
                    className={'h-margin-bottom-25'}
                  >
                    Visualisation
                  </AcHeading>
                </AcColumn>
              </AcRow>

              <AcRow>
                <AcColumn>{renderHeroVisual}</AcColumn>
              </AcRow>
            </AcColumn>
          )}
        </AcRow>

        {AcIsSet(renderConfigurationsTable) && (
          <AcRow>
            <AcColumn xs={12} sm={12}>
              <AcRow>
                <AcColumn>
                  <AcHeading
                    tag={'h2'}
                    rank={5}
                    className={'h-margin-bottom-25'}
                  >
                    Configurations
                  </AcHeading>
                </AcColumn>
              </AcRow>

              <AcRow>
                <AcColumn>
                  <AcCard dense flat>
                    {renderConfigurationsTable}
                  </AcCard>
                </AcColumn>
              </AcRow>
            </AcColumn>
          </AcRow>
        )}

        {contracts && (
          <AcRow>
            <AcColumn xs={12} sm={12}>
              <AcRow>
                <AcColumn>
                  <AcHeading
                    tag={'h2'}
                    rank={5}
                    className={'h-margin-bottom-25'}
                  >
                    Contracts
                  </AcHeading>
                </AcColumn>
              </AcRow>
              <AcRow>
                <AcColumn>
                  <AcCard dense flat>
                    {contracts}
                  </AcCard>
                </AcColumn>
              </AcRow>
            </AcColumn>
          </AcRow>
        )}
      </AcContainer>
    </div>
  );
};

export default withStore(observer(AcVibratoryHammerDetailOverviewTab));
